<template>
  <div>
    <v-btn small color="info" @click="abrirModal()">
      <small>Tutoriales</small> <v-icon right>{{ icons.mdiVideoOutline }}</v-icon>
    </v-btn>

    <v-dialog v-model="dialog" width="800" scrollable>
      <v-card>
        <v-card-title class="text-h5 grey lighten-2"> Tutoriales </v-card-title>
        <v-card-text class="text-center" v-if="cargando">
          <span>Cargando los tutoriales</span>
        </v-card-text>
        <v-card-text style="max-height: 500px" v-if="cargando == false">
          <div v-for="(item, index) in datos" :key="index">
            <v-card>
              <v-card-text>
                <strong>{{ item.nombre }}</strong><br>
                <small>Duracion: {{item.minutos}}</small>
              </v-card-text>
              <v-card-actions>
                <small>{{ item.descripcion }}</small>
                <v-spacer></v-spacer>

                <v-btn icon @click="item.show = !item.show">
                  VER<v-icon> {{ item.show ? icons.mdiChevronUp : icons.mdiChevronDown }}</v-icon>
                </v-btn>
              </v-card-actions>
              <v-expand-transition>
                <div v-show="item.show">
                  <v-divider></v-divider>
                  <v-card-text>
                    <iframe
                      v-if="dialog"
                      src="https://www.youtube.com/embed/kdnqApxyc10?si=cT0CqJjiU38BZmf-"
                      width="100%"
                      height="400"
                    ></iframe>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-card>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialog = false"> cerrar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template> 
  <script>
import { ref } from '@vue/composition-api'
import { mdiVideoOutline, mdiChevronUp, mdiChevronDown } from '@mdi/js'
import MaestroService from '@/api/servicios/MaestroService'
export default {
  props: {
    id_modulo: Number,
  },
  setup(props) {
    const dialog = ref(false)
    const abrirModal = () => {
      dialog.value = true
      cargar()
    }
    const cargando = ref(false)
    const datos = ref([])
    const cargar = () => {
      cargando.value = true
      try {
        MaestroService.tutorialesListar({ id_modulo: props.id_modulo })
          .then(response => {
            datos.value = response.data.datos
            console.log(response.data.datos)
          })
          .catch(err => {})
          .finally(() => {
            cargando.value = false
          })
      } catch (errr) {
        cargando.value = false
      }
    }
    return {
      cargar,
      abrirModal,
      dialog,
      datos,
      cargando,
      icons: {
        mdiVideoOutline,
        mdiChevronUp,
        mdiChevronDown,
      },
    }
  },
}
</script>