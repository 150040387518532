<template>
  <v-card flat class="pa-3 mt-2">
    <v-app-bar flat color="rgba(0, 0, 0, 0)">
      <v-toolbar-title class="text-h6 pl-0"> Combos </v-toolbar-title>

      <v-spacer></v-spacer>

      <ModalTutorial :id_modulo="modulo.inventario_producto" />
    </v-app-bar>

    <v-card-text>
      <v-form ref="form" lazy-validation>
        <v-card flat>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      :rules="reglas.requerido"
                      v-model="datos.nombre"
                      label="Nombre"
                      required
                      outlined
                      dense
                      hide-details="auto"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-textarea
                      v-model="datos.descripcion"
                      label="Decripcion"
                      hide-details="auto"
                      dense
                      outlined
                      rows="3"
                    ></v-textarea>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      :rules="reglas.referencia"
                      v-model="datos.referencia"
                      name="referencia"
                      label="Referencia*"
                      ref="referenciaRef"
                      required
                      outlined
                      hide-details="auto"
                      dense
                      @keyup="consultarReferenciaExisteProducto()"
                      :suffix="
                        datos.ind_referencia_existe_buscando == true
                          ? '...'
                          : datos.ind_referencia_existe == false && datos.referencia != ''
                          ? 'DISPONIBLE'
                          : datos.ind_referencia_existe == true
                          ? 'DUPLICADA'
                          : ''
                      "
                    ></v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <SelectCategoria
                      :rules="reglas.requerido"
                      v-model="datos.categoria"
                      :add="false"
                      :dense="true"
                      :outlined="true"
                    />
                  </v-col>
                  <v-col cols="12">
                    <SelectSubCategoria
                      :rules="reglas.requerido"
                      v-model="datos.sub_categoria"
                      :padre="datos.categoria"
                      :add="false"
                      :dense="true"
                      :outlined="true"
                    />
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <CropImagenVue
                      ref="refImagen"
                      :requerido="false"
                      :rounded="true"
                      :urlImgDefault="urlImgDefault"
                      :urlImgValue="urlImgValue"
                    />
                    <v-divider />
                  </v-col>
                  <v-col cols="12">
                    <SelectEstado
                      :rules="reglas.requerido"
                      v-model="datos.estado"
                      :padre="2"
                      :add="false"
                      :dense="true"
                      :outlined="true"
                    />
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <v-row>
              <v-col cols="12"
                ><p class="text-center"><strong>Combo</strong></p></v-col
              >
              <small class="error--text" v-if="datos.combo_detalles.length <= 1">
                Debes agregar por lo menos 2 productos al combo
              </small>
              <v-col cols="12">
                <BuscarProducto
                  :label="`Producto`"
                  class="mb-4 mt-2"
                  @GetProducto="GetProductoCombo"
                  ref="RefProductoCombo"
                />
              </v-col>
              <v-col cols="12">
                <v-simple-table>
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Producto</th>
                      <th>Area/Locatario</th>
                      <th>Costo</th>
                      <th>Cantidad</th>
                      <th>PVP</th>
                      <th>Total</th>
                      <th>Accion</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in datos.combo_detalles" :key="index">
                      <td>{{ item.id }}</td>
                      <td>{{ item.nombre }}</td>
                      <td>
                        <v-select
                          class="mt-0"
                          v-model="item.id_puesto"
                          item-text="nombre"
                          item-value="id_puesto"
                          :items="item.puestos.filter(item => item.ind_activo)"
                          dense
                          outlined
                          hide-details=""
                        ></v-select>
                      </td>
                      <td>{{ item.costo }}</td>
                      <td>
                        <v-text-field
                          @keyup="calcularCostoPvpCombo()"
                          v-model="item.cantidad"
                          dense
                          outlined
                          hide-details=""
                        ></v-text-field>
                      </td>

                      <td>
                        <v-text-field
                          @keyup="calcularCostoPvpCombo()"
                          v-model="item.pvp"
                          dense
                          outlined
                          hide-details=""
                        ></v-text-field>
                      </td>
                      <td>{{ item.pvp * item.cantidad }}</td>
                      <td>
                        <v-btn icon color="error" small @click="eliminarProdcutoCombo(item)">
                          <v-icon small>{{ icons.mdiDelete }}</v-icon>
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                  <tfoot>
                    <tr>
                      <td colspan="8" class="text-right">
                        <strong>Total Costo: {{ funciones.formatoNumeric(datos.costo) }}</strong>
                        <br />
                        <strong>Total PVP: {{ funciones.formatoNumeric(datos.pvp) }}</strong>
                      </td>
                    </tr>
                  </tfoot>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <v-row>
              <v-col cols="12">
                <p class="text-center mt-2"><strong> Costos y precios</strong></p>
              </v-col>
              <v-col cols="12" v-if="datos.tipo_producto == 3">
                <span class="text-center mt-2 error--text"
                  >El pvp y costo se calculan segun los productos agregados al combo</span
                >
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      @keyup="changePvp('costo')"
                      :rules="reglas.numerico_requerido"
                      v-model="datos.costo"
                      label="Costo"
                      required
                      outlined
                      dense
                      hide-details="auto"
                      type="number"
                      :disabled="
                        datos.tipo_producto == 3 ||
                        (datos.receta && datos.receta.id) ||
                        (datos.productos_padres && datos.productos_padres.length > 0)
                      "
                    ></v-text-field>
                    <span v-if="datos.receta && datos.receta.id" class="info--text"
                      >El costo se calculan segun los ingredientes de su receta</span
                    >
                    <span v-if="datos.productos_padres && datos.productos_padres.length > 0" class="info--text"
                      >El costo se calculan segun el costo de su relacion<br />
                      <small v-for="(dat, ind) in datos.productos_padres" :key="ind">
                        {{ dat.producto.id }} {{ dat.producto.nombre }} <br />
                      </small>
                    </span>
                  </v-col>

                  <v-col cols="12">
                    <v-row>
                      <v-col cols="12">
                        <v-text-field
                          :rules="reglas.numerico_requerido"
                          v-model="datos.utilidad"
                          @keyup="changePvp('utilidad')"
                          label="Ganancia"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          suffix="%"
                          :disabled="datos.tipo_producto == 3"
                          type="number"
                        ></v-text-field>
                        <span v-if="datos.descuento > 0" class="pt-2 mt-2 info--text"
                          >Ahora:
                          <strong>
                            {{
                              (((datos.pvp - datos.pvp * (datos.descuento / 100)) / datos.costo - 1) * 100).toFixed(4)
                            }}</strong
                          >
                        </span>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          @keyup="changePvp('margen')"
                          :rules="reglas.numerico_requerido"
                          v-model="datos.margenUtilidad"
                          label="Margen Utilidad"
                          required
                          outlined
                          dense
                          hide-details="auto"
                          suffix="%"
                          :disabled="datos.tipo_producto == 3"
                          type="number"
                        ></v-text-field>
                        <span v-if="datos.descuento > 0" class="pt-2 mt-2 info--text"
                          >Ahora:
                          <strong>
                            {{
                              ((datos.costo / (datos.pvp - datos.pvp * (datos.descuento / 100))) * 100).toFixed(4)
                            }}</strong
                          >
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="6">
                <v-row>
                  <v-col cols="12">
                    <SelectTasaImpuesto
                      @change="changePvp('pvp')"
                      :rules="reglas.requerido"
                      v-model="datos.impuesto_iva_json"
                      :padre="1"
                      :add="false"
                      :dense="true"
                      :outlined="true"
                      type="number"
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      @keyup="changePvp('pvp')"
                      :rules="reglas.numerico_requerido"
                      v-model="datos.pvp"
                      label="PVP"
                      required
                      outlined
                      dense
                      hide-details="auto"
                      :disabled="datos.tipo_producto == 3"
                      type="number"
                    ></v-text-field>
                    <span v-if="datos.descuento > 0" class="pt-2 mt-2 info--text"
                      >Ahora: <strong> {{ (datos.pvp - datos.pvp * (datos.descuento / 100)).toFixed(2) }}</strong>
                    </span>
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field
                      @keyup="changePvp('pvp')"
                      :rules="reglas.numerico_requerido"
                      v-model="datos.descuento"
                      label="Descuento"
                      required
                      outlined
                      dense
                      hide-details="auto"
                      suffix="%"
                      type="number"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                      @keyup="changePvp('pvp_iva')"
                      :rules="reglas.numerico_requerido"
                      v-model="datos.pvp_iva"
                      label="PVP + IVA"
                      required
                      outlined
                      dense
                      hide-details="auto"
                      :disabled="datos.tipo_producto == 3"
                      type="number"
                    ></v-text-field>

                    <span v-if="datos.descuento > 0" class="pt-2 mt-2 info--text"
                      >Ahora:
                      <strong> {{ (datos.pvp_iva - datos.pvp_iva * (datos.descuento / 100)).toFixed(2) }}</strong>
                    </span>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-divider class="my-6"></v-divider>
            <v-row>
              <v-col cols="12">
                <p class="text-center mt-2"><strong> Indicadores</strong></p>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-checkbox
                  label="Responsable/Comisiones"
                  v-model="datos.ind_responsable"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>

              <v-col cols="12" md="6" lg="4">
                <v-checkbox
                  label="Activo Servicio"
                  v-model="datos.ind_servicio"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-checkbox
                  label="Procesar Comanda"
                  v-model="datos.ind_imprimir_auto"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12" md="6" lg="4">
                <v-checkbox
                  label="Maneja Inventario"
                  v-model="datos.ind_inventario"
                  color="primary"
                  hide-details
                ></v-checkbox>
              </v-col>
              <v-col cols="12">
                <p>Seguridad producto venta</p>
                <v-divider></v-divider>
                <v-radio-group v-model="datos.nivel_seguridad" row>
                  <v-radio label="Sin seguridad" :value="0"></v-radio>
                  <v-radio label="Seguridad Nivel 1" :value="1"></v-radio>
                  <v-radio label="Seguridad Nivel 2" :value="2"></v-radio>
                  <v-radio label="Seguridad Nivel 3" :value="3"></v-radio>
                </v-radio-group>
              </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>

            <v-row>
              <v-col cols="12">
                <p class="text-center mt-2"><strong> Locatario/Areas</strong></p>
              </v-col>

              <v-col cols="12" sm="6" md="4" lg="3" v-for="(item, index) in datos.puestos" :key="index" class="py-0">
                <v-checkbox :label="item.nombre" v-model="item.ind_activo" color="primary" hide-details></v-checkbox>
              </v-col>
            </v-row>
            <v-divider class="my-6"></v-divider>
            <ProgramacionProducto
              :lista="datos.programacion"
              @agregarProgramacion="agregarProgramacion"
              @eliminarProgramacion="eliminarProgramacion"
            />
          </v-card-text>
        </v-card>
      </v-form>

      <v-row>
        <v-col cols="12" class="text-right">
          <v-divider class="mt-4 mb-4"></v-divider>
          <v-btn color="secondary" class="me-3" @click="limpiar()" :disabled="guardando" small> Limpiar </v-btn>
          <v-btn color="primary" class="me-3" small @click="Guardar()" :disabled="guardando" :loading="guardando">
            Guardar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import { onBeforeMount, ref } from '@vue/composition-api'
import store from '@/store'
import goTo from 'vuetify/lib/services/goto'
import ProductosService from '@/api/servicios/ProductosService'
import SelectProductoTipo from '@/views/sistema/inventario/maestro/producto-tipo/componentes/Select.vue'
import SelectCategoria from '@/views/sistema/inventario/maestro/categoria/componentes/Select.vue'
import SelectSubCategoria from '@/views/sistema/inventario/maestro/sub-categoria/componentes/Select.vue'
import SelectUnidadMedida from '@/views/sistema/inventario/maestro/unidad-medida/componentes/Select.vue'
import SelectEstado from '@/views/sistema/administracion/archivo/estado/componentes/Select.vue'
import SelectTasaImpuesto from '@/views/sistema/administracion/archivo/impuesto-tasa/componentes/SelectJson.vue'
import BuscarProducto from './BuscarProducto.vue'
import config from '@/api/config'
import CropImagenVue from '@/components/CropImagen.vue'
import PuestoService from '@/api/servicios/PuestoService'
import { mdiDelete, mdiInformation, mdiVideoOutline } from '@mdi/js'
import funciones from '@/funciones/funciones'
import TooltipInfo from '@/components/TooltipInfo.vue'
import ModalTutorial from '@/components/ModalTutorial.vue'
import { modulo } from '@/modulos'
import ProgramacionProducto from '../../producto/componentes/ProgramacionProducto.vue'
export default {
  components: {
    SelectProductoTipo,
    SelectCategoria,
    SelectSubCategoria,
    SelectUnidadMedida,
    CropImagenVue,
    BuscarProducto,
    SelectEstado,
    SelectTasaImpuesto,
    TooltipInfo,
    ModalTutorial,
    ProgramacionProducto
  },
  setup(props, context) {
    const refImagen = ref(null)
    const BuscarProductoRef = ref(null)
    const datoInit = {
      id: -1,
      puestos: [],
      referencia: '',
      ind_referencia_existe: false,
      ind_referencia_existe_buscando: false,
      descripcion: '',
      categoria: '',
      sub_categoria: '',
      estado: 1,
      unidad: 5,
      img: '',
      precio: null,
      puesto: '',
      nombre: '',
      costo: null,
      utilidad: null,
      margenUtilidad: null,
      extra: false,
      activo_venta: true,
      tipo_producto: 3,
      ingredientes: [],
      und_caja: '0',
      extras: [],
      impuesto_iva: '',
      impuesto_iva_json: null,
      precio_iva: null,
      combo_detalles: [],
      producto_ing: null,
      cantidad_ingrediente: '0',
      empleado: null,
      productos_ing: [],
      ind_compra: false,
      ind_responsable: false,
      ind_servicio: true,
      ind_imprimir_auto: true,
      descuento: 0,
      ind_inventario: true,
      ind_venta: false,
      nivel_seguridad: 0,
      programacion: [],
    }
    const tab = ref('tab-1')
    const puestos = ref([])
    const datos = ref(JSON.parse(JSON.stringify(datoInit)))
    const urlImgDefault = ref(require('@/assets/images/3d-img/store.jpg'))
    const urlImgValue = ref(config.api_imagenes + 'puesto/img/' + datos.value.img)
    const guardando = ref(false)
    const form = ref(null)
    const form2 = ref(null)
    const form3 = ref(null)
    const form4 = ref(null)
    const form5 = ref(null)
    const form6 = ref(null)
    const form7 = ref(null)
    const agregarProgramacion = item => {
      console.log('agregarProgramacion', item)
      datos.value.programacion.push({
        ...item,
        dias_semana: item.dias_semana.length > 0 ? item.dias_semana.join(',') : '',
        meses: item.meses.length > 0 ? item.meses.join(',') : '',
        dias_mes: item.dias_mes.length > 0 ? item.dias_mes.join(',') : '',
      })
    }

    const eliminarProgramacion = item => {
      datos.value.programacion = datos.value.programacion.filter(it => it.id != item.id)
    }

    const reglas = {
      referencia: [
        v => !!v || 'Es requerido',
        v =>
          (datos.value.ind_referencia_existe == true && !!v ? false : true) ||
          'La referencia ya existe, utiliza otra diferente',
      ],
      requerido: [v => !!v || 'Es requerido'],
      numerico_requerido: [
        v => (v?.toString() == '0' ? true : !!v || 'Es requerido'),
        v => !isNaN(v) || 'No es numero valido',
        v => !(v == Infinity) || 'No es numero valido',
      ],
    }
    const referenciaRef = ref(null)

    const consultarReferenciaExisteProducto = () => {
      datos.value.ind_referencia_existe_buscando = true
      datos.value.ind_referencia_existe = false
      ProductosService.consultarReferenciaExisteProducto({ referencia: datos.value.referencia })
        .then(response => {
          datos.value.ind_referencia_existe_buscando = false
          if (response.data.mensaje == 'EXISTE') {
            datos.value.ind_referencia_existe = true
          }

          //form.value?.validate('referencia')
          referenciaRef.value.validate()
        })
        .catch(error => {
          console.log('error', error)
        })
    }

    const calcularCostoPvpCombo = () => {
      var costo = 0
      var pvp = 0

      datos.value.combo_detalles.forEach(element => {
        if (element.cantidad > 0) {
          costo += element.cantidad * element.costo

          if (element.pvp > 0) {
            pvp += element.cantidad * element.pvp
          }
        }
      })

      datos.value.costo = costo
      datos.value.pvp = pvp
      changePvp('pvp')
    }

    const changePvp = tipo => {
      switch (tipo) {
        case 'costo':
          if (datos.value.pvp > 0) {
            datos.value.utilidad = ((datos.value.pvp / datos.value.costo - 1) * 100).toFixed(4)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(4)
          } else {
            datos.value.utilidad = 0.0
            datos.value.margenUtilidad = 100.0
            datos.value.pvp = datos.value.costo
            datos.value.pvp_iva = (
              datos.value.pvp *
              (1 + (datos.value.impuesto_iva_json ? datos.value.impuesto_iva_json.valor / 100 : 0))
            ).toFixed(4)
          }
          break
        case 'pvp':
          if (datos.value.costo > 0) {
            datos.value.utilidad = ((datos.value.pvp / datos.value.costo - 1) * 100).toFixed(2)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(2)
            datos.value.pvp_iva = (
              datos.value.pvp *
              (1 + (datos.value.impuesto_iva_json ? datos.value.impuesto_iva_json.valor / 100 : 0))
            ).toFixed(4)
          } else {
            if (datos.value.pvp_iva != null) {
              datos.value.utilidad = 0.0
              datos.value.margenUtilidad = 100
              datos.value.costo = datos.value.pvp
              datos.value.pvp_iva = (
                datos.value.pvp *
                (1 + (datos.value.impuesto_iva_json ? datos.value.impuesto_iva_json.valor / 100 : 0))
              ).toFixed(4)
            }
          }
          break
        case 'utilidad':
          if (datos.value.costo > 0) {
            datos.value.pvp = (datos.value.costo * (datos.value.utilidad / 100 + 1)).toFixed(4)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(2)
            datos.value.pvp_iva = (
              datos.value.pvp *
              (1 + (datos.value.impuesto_iva_json ? datos.value.impuesto_iva_json.valor / 100 : 0))
            ).toFixed(4)
          } else {
            datos.value.costo = 1
            datos.value.pvp = (datos.value.costo * (datos.value.utilidad / 100 + 1)).toFixed(4)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(2)
            datos.value.pvp_iva = (
              datos.value.pvp *
              (1 + (datos.value.impuesto_iva_json ? datos.value.impuesto_iva_json.valor / 100 : 0))
            ).toFixed(4)
          }
          break

        case 'margen':
          if (datos.value.costo > 0) {
            datos.value.pvp = ((datos.value.costo * 100) / datos.value.margenUtilidad).toFixed(4)
            datos.value.utilidad = ((datos.value.pvp / datos.value.costo - 1) * 100).toFixed(2)
            datos.value.pvp_iva = (
              datos.value.pvp *
              (1 + (datos.value.impuesto_iva_json ? datos.value.impuesto_iva_json.valor / 100 : 0))
            ).toFixed(4)
          } else {
            datos.value.costo = 1
            datos.value.pvp = ((datos.value.costo * 100) / datos.value.margenUtilidad).toFixed(4)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(2)
            datos.value.pvp_iva = (
              datos.value.pvp *
              (1 + (datos.value.impuesto_iva_json ? datos.value.impuesto_iva_json.valor / 100 : 0))
            ).toFixed(4)
          }
          break

        case 'pvp_iva':
          if (datos.value.costo > 0) {
            datos.value.pvp = (
              datos.value.pvp_iva /
              (1 + (datos.value.impuesto_iva_json ? datos.value.impuesto_iva_json.valor / 100 : 0))
            ).toFixed(4)
            datos.value.utilidad = ((datos.value.pvp / datos.value.costo - 1) * 100).toFixed(2)
            datos.value.margenUtilidad = ((datos.value.costo / datos.value.pvp) * 100).toFixed(2)
          } else {
            datos.value.pvp = (
              datos.value.pvp_iva /
              (1 + (datos.value.impuesto_iva_json ? datos.value.impuesto_iva_json.valor / 100 : 0))
            ).toFixed(4)
            datos.value.costo = datos.value.pvp
            datos.value.utilidad = 0.0
            datos.value.margenUtilidad = 100
          }
          break

        default:
          break
      }
    }

    onBeforeMount(() => {
      cargarPuestos()
    })
    const producto_ing = ref({
      producto: null,
      cantidad: 0,
    })

    const RefProductoExtra = ref(null)
    const RefProductoCombo = ref(null)
    const GetProducto = item => {
      producto_ing.value.producto = item
      producto_ing.value.cantidad = 0
    }
    const eliminarProdcutoExtra = item => {
      datos.value.extras = datos.value.extras.filter(ele => ele.id != item.id)
    }
    const eliminarProdcutoCombo = item => {
      datos.value.combo_detalles = datos.value.combo_detalles.filter(ele => ele.id != item.id)
      calcularCostoPvpCombo()
    }

    const GetProductoCombo = item => {
      if (item != undefined) {
        if (datos.value.combo_detalles.find(ele => ele.id == item.id) == undefined) {
          var id_puesto = null
          if (item.puestos.filter(item => item.ind_activo) > 0) {
            id_puesto = item.puestos.filter(item => item.ind_activo)[0].id_puesto
          }

          datos.value.combo_detalles.push({
            ...item,
            cantidad: 1,
            pvp: item.precio_venta,
            id_puesto: item.puestos.filter(item => item.ind_activo)[0].id,
          })
          calcularCostoPvpCombo()
        } else {
          store.commit('setAlert', {
            message: 'Ya esta agregado.',
            type: 'warning',
          })
        }
        setTimeout(() => {
          RefProductoCombo.value.model = null
        }, 100)
      }
    }
    const GetProductoExtra = item => {
      if (item != undefined) {
        console.log(
          'GetProductoExtra',
          datos.value.extras.find(ele => ele.id == item.id),
        )

        if (datos.value.extras.find(ele => ele.id == item.id) == undefined) {
          datos.value.extras.push(item)
        } else {
          store.commit('setAlert', {
            message: 'Ya esta agregado como extra',
            type: 'warning',
          })
        }
        setTimeout(() => {
          RefProductoExtra.value.model = null
        }, 100)
      }
    }
    const limpiar = () => {
      const impuesto_iva_json = datos.value.impuesto_iva_json
      datos.value = JSON.parse(JSON.stringify(datoInit))
      datos.value.impuesto_iva_json = impuesto_iva_json
      datos.value.puestos = asignarPuestos(puestos.value)
      refImagen.value.resetImg()
      form.value.resetValidation()
    }

    const cargandos = ref(false)
    const agregarExtra = () => {
      datos.value.extras
    }
    const CargarEditar = item => {
      datos.value = {
        ...item,
        tipo_producto: item.id_producto_tipo,
        unidad: item.unidad_medida.id,
        impuesto_iva: item.id_tasa_impuesto_iva,
        impuesto_iva_json: item.impuesto_tasa,
        pvp: item.precio_venta,
        und_caja: item.und_caja.toString(),
        categoria: item.categoria.id,
        sub_categoria: item.sub_categoria.id,
      }
      changePvp('pvp')
      urlImgValue.value = config.api_imagenes + '/producto/img/' + datos.value.img + '?' + Math.random()
      /*
      id: -1,
      puestos: [],
      referencia: '',
      ind_referencia_existe: false,
      ind_referencia_existe_buscando: false,
      descripcion: '',
      categoria: '',
      sub_categoria: '',
      estado: 1,
      unidad: '',
      img: '',
      precio: null,
      puesto: '',
      nombre: '',
      costo: null,
      utilidad: null,
      margenUtilidad: null,
      extra: false,
      activo_venta: true,
      tipo_producto: '',
      ingredientes: [],
      und_caja: '0',
      extras: [],
      impuesto_iva: '',
      impuesto_iva_json: null,
      precio_iva: null,
      combo_detalles: [],
      producto_ing: null,
      cantidad_ingrediente: '0',
      empleado: null,
      productos_ing: [],
      */
      goTo(0)
    }
    const validarForm = () => {
      let val = form.value?.validate()
      if (datos.value.combo_detalles.length <= 1) {
        val = false
      }

      return val
    }
    const ActualizarLista = item => {
      console.log(item)
      context.emit('GetActualizarLista', { ...item })
    }

    const asignarPuestos = dato_in => {
      let x = []
      dato_in.forEach(element => {
        x.push({ ...element, ind_activo: false })
      })
      return x
    }
    const cargarPuestos = () => {
      PuestoService.obtenerPuestos()
        .then(response => {
          if (response.data.mensaje == 'BUSQUEDA_EXITOSA') {
            puestos.value = response.data.datos
            datos.value.puestos = asignarPuestos(response.data.datos)
            console.log('puestos', response.data)
          }
        })
        .catch(error => {
          console.log('puestos error', error)
        })
        .finally(() => {})
    }
    const producto_ing_agregar = () => {
      if (datos.value.productos_ing.find(it => it.producto.id == producto_ing.value.producto.id) == undefined) {
        const x = JSON.parse(JSON.stringify(producto_ing.value))
        datos.value.productos_ing.push(x)
        producto_ing.value = {
          producto: null,
          cantidad: 0,
        }
        BuscarProductoRef.value.limpiar()
      } else {
        store.commit('setAlert', {
          message: 'existe en la lista',
          type: 'warning',
        })
      }
    }
    const eliminar_productos_ing = item => {
      datos.value.productos_ing = datos.value.productos_ing.filter(it => it.producto.id != item.producto.id)
    }
    const Guardar = async () => {
      guardando.value = true
      let imagen = ''
      if (validarForm()) {
        if (refImagen.value.datos.imgInput) {
          const form = new FormData()
          form.append('file', refImagen.value.datos.imgInput, 'img.jpg')
          //SUBIR IMAGEN
          console.log('asq')

          await ProductosService.subirImgProducto(form)
            .then(response => {
              console.log('subirImgProducto', response.data)
              imagen = response.data.img
              datos.value.img = imagen
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              console.log('Termino')
            })

          const formData = new FormData()
          formData.append('avatar', refImagen.value.datos.imgInput, 'avatar.jpg')
          formData.append('file', refImagen.value.datos.imgInput)
          formData.append('nombre', imagen)
          formData.append('ruta', 'product')

          /*   await ProductosService.subirImgProductoPHP(formData)
            .then(response => {
              console.log(response)
            })
            .catch(error => {
              console.log(error)
            })
            .finally(() => {
              console.log('Termino')
            })
*/
          datos.value.img = imagen
        }

        try {
          ProductosService.productoActualizar({
            ...datos.value,
            empleado: store.state.user.id_empleado,
            impuesto_iva: datos.value.impuesto_iva_json.id,
          })
            .then(response => {
              console.log(response)
              if (response.data.mensaje == 'PRODUCTO GUARDADO CON EXITO') {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'success',
                })
              } else {
                store.commit('setAlert', {
                  message: response.data.mensaje,
                  type: 'waring',
                })
              }

              ActualizarLista(null)
              limpiar()
            })
            .catch(error => {
              console.log(error)
              store.commit('setAlert', {
                message: error,
                type: 'error',
                
                error: error,
                funcion: 'Guardar',
              })
            })
            .finally(() => {
              guardando.value = false
            })
        } catch (error) {
          console.log(error)
          guardando.value = false
          store.commit('setAlert', {
            message: error,
            type: 'error',
            error: error,
            funcion: 'Guardar',
          })
        }
      } else {
        guardando.value = false
        store.commit('setAlert', {
          message: 'Verifique que todos los datos estén completos',
          type: 'warning',
        })
      }
    }

    return {
      icons: {
        mdiDelete,
        mdiInformation,
        mdiVideoOutline,
      },
      modulo,
      guardando,
      reglas,
      datos,
      CargarEditar,
      cargandos,
      limpiar,
      Guardar,
      form,
      ActualizarLista,
      urlImgDefault,
      urlImgValue,
      config,
      refImagen,
      puestos,
      GetProducto,
      GetProductoExtra,
      RefProductoExtra,
      eliminarProdcutoExtra,
      changePvp,
      eliminar_productos_ing,
      producto_ing,
      producto_ing_agregar,
      BuscarProductoRef,
      consultarReferenciaExisteProducto,
      referenciaRef,
      GetProductoCombo,
      RefProductoCombo,
      eliminarProdcutoCombo,
      funciones,
      calcularCostoPvpCombo,
      tab,
      form2,
      form3,
      form4,
      form5,
      form6,
      form7,
      agregarProgramacion,
      eliminarProgramacion,
    }
  },
}
</script>