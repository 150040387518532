<template>
  <div>
   
      <p class="text-center mt-2"><strong>Programacion Activacion Venta</strong></p>
 

    <v-col cols="12">
      <div class="text-right mb-2">
        <v-btn @click="abrir()" small color="primary">Agregar</v-btn>
      </div>
     
      <v-simple-table dense>
        <thead>
          <tr>
            <th>Id</th>
            <th>Descripcion</th>
            <th>Periodo</th>
            <th>Estado</th>
            <th>Accion</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in lista" :key="index">
            <td>{{ item.id < 0 ?'Nuevo': item.id }}</td>
            <td>{{ item.descripcion }}</td>
            <td>{{ DescripcionProgramacion(item) }}</td>
            <td>{{ estadoSelect(item.id_estado).descripcion }}</td>
            <td>
              <v-btn icon color="primary" small v-if="item.id_estado == 6" @click="item.id_estado = 5">
                <v-icon small>{{ icons.mdiCheck }}</v-icon>
              </v-btn>
              <v-btn icon color="secondary" small v-if="item.id_estado == 5" @click="item.id_estado = 6">
                <v-icon small>{{ icons.mdiBlockHelper }}</v-icon>
              </v-btn>
              <v-btn icon color="error" small>
                <v-icon small @click="eliminar(item)" >{{ icons.mdiClose }}</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </v-simple-table>
    </v-col>
    <v-dialog v-model="dialog" width="600" scrollable persistentc>
      <v-card>
        <v-card-title class="text-h6 grey lighten-2"> Programacion de ventas de producto </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-form ref="form" lazy-validation>
            <v-row>
              <v-col cols="6">
                <DatePicher
                  :rules="reglas.requerido"
                  v-model="datos.fecha_desde"
                  :label="'Fecha Inicio'"
                  :dense="true"
                  :outlined="true"
                />
              </v-col>
              <v-col cols="6">
                <v-row class="pt-3" justify="center">
                  <v-checkbox v-model="datos.ind_fecha_fin" />

                  <DatePicher
                    :rules="datos.ind_fecha_fin ? reglas.requerido : []"
                    v-model="datos.fecha_hasta"
                    :disabled="!datos.ind_fecha_fin"
                    :label="'Fecha Fin'"
                    :dense="true"
                    :outlined="true"
                  />
                </v-row>
              </v-col>
              <v-col cols="12">
                <v-select
                  hide-details="auto"
                  dense
                  outlined
                  v-model="datos.id_estado"
                  item-text="descripcion"
                  item-value="id"
                  cols="12"
                  :items="estados"
                  label="Estado"
                ></v-select>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  hide-details="auto"
                  dense
                  outlined
                  v-model="datos.dias_semana"
                  :items="dias"
                  filled
                  chips
                  label="Dias de la semana"
                  multiple
                  item-text="nombre"
                  item-value="id"
                  :disabled="datos.dias_mes.length > 0"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(datos.dias_semana, data.item.id)"
                    >
                      {{ data.item.nombre }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item.nombre"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  hide-details="auto"
                  :disabled="datos.dias_semana.length > 0"
                  dense
                  outlined
                  v-model="datos.dias_mes"
                  :items="diasMes"
                  filled
                  chips
                  label="Dias del mes"
                  multiple
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(datos.dias_mes, data.item)"
                    >
                      {{ data.item }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                  hide-details="auto"
                  dense
                  outlined
                  v-model="datos.meses"
                  :items="meses"
                  filled
                  chips
                  label="Meses"
                  multiple
                  item-text="nombre"
                  item-value="id"
                >
                  <template v-slot:selection="data">
                    <v-chip
                      v-bind="data.attrs"
                      :input-value="data.selected"
                      close
                      @click="data.select"
                      @click:close="remove(datos.meses, data.item.id)"
                    >
                      {{ data.item.nombre }}
                    </v-chip>
                  </template>
                  <template v-slot:item="data">
                    <template v-if="typeof data.item !== 'object'">
                      <v-list-item-content v-text="data.item.nombre"></v-list-item-content>
                    </template>
                    <template v-else>
                      <v-list-item-content>
                        <v-list-item-title v-html="data.item.nombre"></v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="6">
                <v-text-field
                  v-model="datos.hora_activacion"
                  :rules="reglas.requerido"
                  dense
                  outlined
                  label="Hora Activacion"
                  type="time"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="datos.hora_desactivacion"
                  :rules="reglas.requerido"
                  dense
                  outlined
                  label="Hora Desactivacion"
                  type="time"
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                  v-model="datos.descripcion"
                  :rules="reglas.requerido"
                  dense
                  outlined
                  label="Descripcion"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <span>{{ DescripcionProgramacion(datos) }}</span>
              </v-col>
            </v-row>
          </v-form> 
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" small @click="dialog = false"> Cerrar </v-btn>
          <v-btn color="primary" small @click="agregarProgramacion()"> Listo </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import DatePicher from '@/components/DatePicker.vue'
import moment from 'moment'
import funciones from '@/funciones/funciones'
import { mdiClose, mdiCheck, mdiDelete, mdiBlockHelper } from '@mdi/js'
export default {
  components: {
    DatePicher,
  },
  props: {
    lista: Array,
  },
  setup(props, context) {
    const dialog = ref(false)
    const dias = [
      { id: '1', nombre: 'Lunes' },
      { id: '2', nombre: 'Martes' },
      { id: '3', nombre: 'Miercoles' },
      { id: '4', nombre: 'Jueves' },
      { id: '5', nombre: 'Viernes' },
      { id: '6', nombre: 'Sabado' },
      { id: '7', nombre: 'Domingo' },
    ]
    const meses = [
      { id: '1', nombre: 'Enero' },
      { id: '2', nombre: 'Febrero' },
      { id: '3', nombre: 'Marzo' },
      { id: '4', nombre: 'Abril' },
      { id: '5', nombre: 'Mayo' },
      { id: '6', nombre: 'Junio' },
      { id: '7', nombre: 'Julio' },
      { id: '8', nombre: 'Agosto' },
      { id: '9', nombre: 'Septiembre' },
      { id: '10', nombre: 'Octubre' },
      { id: '11', nombre: 'Noviembre' },
      { id: '12', nombre: 'Diciembre' },
    ]
    const estados = [
      { id: 5, descripcion: 'ACTIVO' },
      { id: 6, descripcion: 'INACTIVO' },
    ]
    const reglas = {
      requerido: [v => !!v || 'Es requerido'],
    }

    let contador = -10000
    const idDefaultProducto = () => {
      contador = contador + 1
      return contador
    }

    const abrir = item => {
      dialog.value = true
      if (!item) {
        datos.value = JSON.parse(JSON.stringify(datoInit))
      } else {
        datos.value = item
      }
    }
    const form = ref(null)
    const validarForm = () => {
      let val = form.value?.validate()
      return val
    }
    let diasMes = []
    for (let index = 1; index <= 31; index++) {
      diasMes.push(index)
    }
    const remove = (array, item) => {
      const index = array.indexOf(item)
      if (index >= 0) array.splice(index, 1)
    }

    const eliminar =(item)=>{
        context.emit("eliminarProgramacion",item)
    }

    const agregarProgramacion = () => {
      if (validarForm()) {
        let _id = datos.value.id 
        if (datos.value.id == -1) {
          _id = idDefaultProducto()
        }
        context.emit('agregarProgramacion', { ...datos.value, id: _id })
        dialog.value = false
      }
    }

    const estadoSelect = _dato => {
      return estados.find(it => (it.id == _dato))
    }

    const DescripcionProgramacion = _datos => { 
      let _dias_mes = []
      let _meses  = []
      let _dias_semana = []

      if (typeof  _datos.dias_mes === "string"  ){
         if ( _datos.dias_mes != '') 
         {
          _dias_mes = _datos.dias_mes.split(',')
         }
        
      }else{
        _dias_mes = _datos.dias_mes
      }

      if (typeof  _datos.meses === "string"  ){
        if (_datos.meses != ''){
          _meses = _datos.meses.split(',')
        }
       
      }else{
        _meses = _datos.meses
      }

      if (typeof  _datos.dias_semana === "string"  ){
        if (_datos.dias_semana != '') {
          _dias_semana = _datos.dias_semana.split(',')
        }
        
      }else{
        _dias_semana = _datos.dias_semana
      }


      let descripcion = 'Se ejecutara '
      if ((_dias_semana.length == 0 || _dias_semana.length == 7) && _dias_mes.length == 0) {
        descripcion += ' todos los dias'
      }
      if (_dias_semana.length > 0 && _dias_semana.length < 7) {
        let _dias = ''
        _dias_semana.forEach((element, index) => {
          if (index + 1 == _dias_semana.length && _dias_semana.length > 1 && index > 0) {
            _dias += ' y '
          } else if (index > 0 && _dias_semana.length > 1) {
            _dias += ', '
          }

          _dias += dias.find(it => it.id == element)?.nombre
        })

        descripcion += ' los dias ' + _dias
      }

      if (_dias_mes.length > 0) {
        const _dias = _dias_mes.join(',')

        descripcion += ' los todos los   ' + _dias

        if (_meses.length == 0) {
          descripcion += ' del mes'
        }
      }

      if (_meses.length > 0) {
        let _mesestemp = ''
        _meses.forEach((element, index) => {
          if (index + 1 == _meses.length && _meses.length > 1 && index > 0) {
            _mesestemp += ' y '
          } else if (index > 0 && _meses.length > 1) {
            _mesestemp += ', '
          }
          _mesestemp += meses.find(it => it.id == element)?.nombre
        })
        if (_meses.length > 1) {
          descripcion += ' de los meses de ' + _mesestemp
        } else {
          descripcion += ' del mes de ' + _mesestemp
        }
      }

      if (_datos.fecha_desde != null) {
        if (_datos.ind_fecha_fin) {
          descripcion += ', desde ' + moment(_datos.fecha_desde).format('DD-MM-YYYY')
        } else {
          descripcion += ', a partir del ' + moment(_datos.fecha_desde).format('DD-MM-YYYY')
        }
      }

      if (_datos.fecha_hasta != null && _datos.ind_fecha_fin) {
        descripcion += ' hasta ' + moment(_datos.fecha_hasta).format('DD-MM-YYYY')
      }

      if (_datos.hora_activacion != null && _datos.hora_desactivacion != null) {
        descripcion += `, se activará a las ${moment('2000-01-01 ' + _datos.hora_activacion).format(
          'hh:mm a',
        )} y se desactivará a las ${moment('2000-01-01 ' + _datos.hora_desactivacion).format('hh:mm a ')}`
        //descripcion += funciones.diferenciasHoras(moment('2000-01-01 ' + _datos.hora_activacion), moment('2000-01-01 ' + _datos.hora_desactivacion))
        if (
          funciones.diferenciasHoras(
            moment('2000-01-01 ' + _datos.hora_activacion),
            moment('2000-01-01 ' + _datos.hora_desactivacion),
          ) > 0
        ) {
          descripcion += ' del siguiente dia.'
        }
      }

      return descripcion
    }

    const datoInit = {
      id: -1,
      id_producto: null,
      descripcion: '',
      fecha_desde: moment().format('YYYY-MM-DD'),
      fecha_hasta: null,
      dias_semana: [],
      dias_mes: [],
      meses: [],
      hora_activacion: null,
      hora_desactivacion: null,
      id_empleado: null,
      ind_fecha_fin: false,
      id_estado: 5,
    }

    const datos = ref(JSON.parse(JSON.stringify(datoInit)))

    return {
      dialog,
      dias,
      meses,
      diasMes,
      remove,
      datos,
      DescripcionProgramacion,
      form,
      validarForm,
      reglas,
      agregarProgramacion,
      abrir,
      estados,
      estadoSelect,
      contador,
      idDefaultProducto,
      eliminar,
      icons: {
        mdiClose,
        mdiCheck,
        mdiDelete,
        mdiBlockHelper,
      },
    }
  },
}
</script>